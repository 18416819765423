// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-jobs-page-js": () => import("./../../../src/templates/jobsPage.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-nyheder-page-js": () => import("./../../../src/templates/nyhederPage.js" /* webpackChunkName: "component---src-templates-nyheder-page-js" */),
  "component---src-templates-product-list-page-js": () => import("./../../../src/templates/productListPage.js" /* webpackChunkName: "component---src-templates-product-list-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */)
}

